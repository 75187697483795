.whatsapp {
    &-button {
        @include position(fixed, auto, 1rem, 4.5rem, auto, 5);
        background-color: #00a859;
        border: 1px solid white;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 1.5rem;
        color: white;
        height: 3.5rem;
        display: flex;
        width: 3.5rem;

        @include media-breakpoint-down(md) {
            bottom: 1rem;
        }

        &:hover {
            animation: whatsapp-pulse 1s infinite;
            color: white;
        }
    }

    &-card {
        @include position(fixed, auto, 1rem, 4.5rem, auto, 5);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

        @include media-breakpoint-down(md) {
            bottom: 1rem;
        }

        &.opened{
            display: flex !important;
            flex-direction: column;
        }
    }

    &-header {
        background-color: #00a859;
        position: relative;
        padding: .5rem;

        span {
            font-weight: bold;
            color: white;
        }

        .btn-close {
            @include position(absolute, -.2rem, -.2rem, auto, auto);
            border: 2px solid #00a859;
            background-color: white;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #00a859;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;

            i {
                font-size: .7rem;
            }
        }
    }

    &-body {
        background-image: url("/images/whatsapp-background.jpg");
        background-repeat: no-repeat;
        background-position: center;
        padding: .5rem 1rem 1rem;
        background-size: cover;
        filter: grayscale(60%);
        width: 18rem;

        label {
            font-size: 1rem;
        }

        .form-group {
            margin-bottom: 0;

            .form-check {
                margin: .5rem auto 1rem;

                a {
                    color: black;
                }
            }
        }

        .btn {
            width: 100%;
        }
    }
}
