.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: black;
    z-index: 9999;

    .spinner-border {
        width: 3.5rem;
        height: 3.5rem;
        margin-bottom: 1.5rem;
    }

    &-message {
        font-size: 2rem;
    }
}
