//
// Links
//

a {
    transition: all .3s;
}

//
// Images and content
//

img {
    max-width: 100%;
    height: auto;
}

//
// Chevrons banners
//

.swiper-button-prev, .swiper-button-next {
    text-shadow: 0 0 6px white !important;
    transition: all .5s !important;
    color: black !important;

    @include media-breakpoint-down(md) {
        &::after {
            font-size: 1rem !important;
        }
    }
}

.swiper-button-prev {
    left: 2rem !important;

    @include media-breakpoint-down(md) {
        left: 0 !important;
    }

    &:hover {
        left: 1.5rem !important;

        @include media-breakpoint-down(md) {
            left: -.2rem !important;
        }
    }
}

.swiper-button-next {
    right: 2rem !important;

    @include media-breakpoint-down(md) {
        right: 0 !important;
    }

    &:hover {
        right: 1.5rem !important;

        @include media-breakpoint-down(md) {
            right: -.2rem !important;
        }
    }
}
