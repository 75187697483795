.footer {
    background-color: rgba(0, 0, 0, 0.952);
    padding: 2rem 0 1rem;
    max-width: 1920px;
    font-size: .9rem;
    color: white;
    margin: auto;

    @include media-breakpoint-down(md) {
        padding: 1rem 0 5rem;
    }

    a {
        color: white;

        &:hover {
            color: gray;
        }
    }

    ul {
        justify-content: center;
        align-items: center;
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        li {
            text-align: center;
            padding: 0 .75rem;

            @include media-breakpoint-down(md) {
                margin: .5rem auto !important;
            }

            &:first-child {
                padding-left: 0;
                margin-left: 0;

                @include media-breakpoint-down(md) {
                    padding-left: .75rem !important;
                }
            }

            &:last-child {
                padding-right: 0;
                margin-right: 0;

                @include media-breakpoint-down(md) {
                    padding-right: .75rem !important;
                }
            }

            @include media-breakpoint-up(md) {
                &:not(:first-child) {
                    border-left: 1px solid;
                }
            }
        }
    }

    .info {
        margin-bottom: 2rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }

        a {
            justify-content: center;
            align-items: center;
            display: flex;

            i {
                margin-right: .5rem;
            }
        }
    }

    .policies {
        margin-bottom: 1rem;
    }

    .row {
        .col-md-6 {
            font-size: .8rem;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            &:last-child {
                text-align: end;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    margin-top: 1rem;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}
