// Color system

$primary:       #10212a;
$secondary:     #ffcf18;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   rgba(130, 0, 0);
$body-color:                #212529;

// Links
//
// Style anchor elements.

$link-color:                              $primary;
$link-decoration:                         none;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   none;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Lato', sans-serif;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem;

$font-weight-base:            400;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               .375rem;
$btn-padding-x:               .75rem;
$btn-font-family:             null;
$btn-font-size:               $font-size-base;

$btn-padding-y-sm:            .25rem;
$btn-padding-x-sm:            .5rem;
$btn-font-size-sm:            $font-size-base * .875;

$btn-padding-y-lg:            .5rem;
$btn-padding-x-lg:            1rem;
$btn-font-size-lg:            $font-size-base * 1.25;

$btn-border-width:            1px;

$btn-font-weight:             $font-weight-base;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           .25rem;
$btn-border-radius-lg:        .3rem;
$btn-border-radius-sm:        .2rem;

// Forms

$label-margin-bottom:                   .5rem;

$input-padding-y:                       .375rem;
$input-padding-x:                       .75rem;
$input-font-family:                     null;
$input-font-size:                       $font-size-base;
$input-font-weight:                     $font-weight-base;

$input-padding-y-sm:                    .25rem;
$input-padding-x-sm:                    .5rem;
$input-font-size-sm:                    $font-size-base * .875;

$input-padding-y-lg:                    .5rem;
$input-padding-x-lg:                    1rem;
$input-font-size-lg:                    $font-size-base * 1.25;

$input-bg:                              #fff;

$input-color:                           #495057;
$input-border-color:                    #ced4da;
$input-border-width:                    1px;

$input-border-radius:                   .25rem;
$input-border-radius-lg:                .3rem;
$input-border-radius-sm:                .2rem;

$input-placeholder-color:               #6c757d;
$input-plaintext-color:                 $body-color;

// Breadcrumbs

$breadcrumb-font-size:              .75rem;

$breadcrumb-padding-y:              .75rem;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          1rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $body-color;
$breadcrumb-active-color:           $primary;
$breadcrumb-divider:                quote("/");
