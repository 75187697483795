.navbar {
    background-color: rgb(0, 0, 0);
    transition: all .3s;
    position: relative;
    padding: 1.5rem 0;
    z-index: 1000;
    width: 100%;
    top: 0;

    @include media-breakpoint-down(md) {
        padding: .5rem 1rem;

        &-collapse {
            padding-bottom: 1rem;
        }
    }

    .nav-item {
        margin: auto .5rem;

        @include media-breakpoint-down(md) {
            margin: .5rem auto !important;
            text-align: center;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        a {
            position: relative;
            color: white;

            @include media-breakpoint-down(md) {
                padding: 0;
            }

            &::before {
                content: "";
                background-color: white;
                transition: all .3s;
                position: absolute;
                bottom: .3rem;
                margin: auto;
                height: 2px;
                width: 0;
                right: 0;
                left: 0;

                @include media-breakpoint-down(md) {
                    bottom: -.3rem;
                }
            }
        }

        &:hover, &.active {
            a {
                &::before {
                    width: 80%;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
        }
    }

    #menu_checkbox {
        display: none;

        &+ label {
            cursor: pointer;
            height: 1.8rem;
            z-index: 100;
            width: 3rem;
            margin: 0;

            @include media-breakpoint-down(md) {
                width: 2rem;
            }

            div {
                background-color: #fff;
                transition: all 0.3s;
                border-radius: 50px;
                position: relative;
                margin-bottom: 7px;
                width: 3rem;
                height: 4px;

                @include media-breakpoint-down(md) {
                    width: 2rem;
                }

                &:first-child {
                    transform-origin: 13px;

                    @include media-breakpoint-down(md) {
                        transform-origin: 5px;
                    }
                }

                &:last-child {
                    transform-origin: calc(3rem - 13px);
                    margin-bottom: 0;

                    @include media-breakpoint-down(md) {
                        transform-origin: calc(2rem - 5px);
                    }
                }
            }
        }

        &:checked {
            &+ label {
                &:before {
                    background-color: #4caf50;
                }

                div {
                    &:first-child {
                        transform: rotateZ(45deg);
                    }

                    &:nth-child(2) {
                        transform: rotateZ(-45deg);
                        width: 3.5rem;
                        left: -3px;
                        top: 1px;

                        @include media-breakpoint-down(md) {
                            width: 2.5rem;
                        }
                    }

                    &:last-child {
                        transform: rotateZ(45deg);
                    }
                }
            }
        }
    }
}

.fixed-navbar {
    .navbar {
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        background-color: rgba(0, 0, 0, 0.8);
        padding: .5rem 0;
        position: fixed;

        @include media-breakpoint-down(md) {
            padding: .3rem 1rem;
        }
    }

    main {
        margin-top: 100px;
    }
}
