.policies-bottom {
    @include position(fixed, auto, 5.5rem, 4.5rem, auto, 1040);
    box-shadow: 0px 0px 5px 0px rgba(255,255,255,1);
    background-color: black;
    font-size: .6875rem;
    border-radius: 5px;
    text-align: center;
    width: 21.875rem;
    padding: 1rem;
    color: #fff;
    display: none;

    @include media-breakpoint-down(md) {
        width: calc(100% - 2rem);
        bottom: 1rem;
        right: 1rem;
    }

    a {
        color: white;

        &:hover {
            text-decoration: underline;
        }
    }
}
