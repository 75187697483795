.newsletter {
    background-color: rgba(130, 0, 0, 0.8);
    max-width: 1920px;
    padding: 1rem 0;
    margin: auto;

    .container {
        color: white;

        h2 {
            font-size: 1.5rem;

            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 1rem;
            }
        }

        hr {
            border-color: white;
        }

        .card {
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 15px;
            margin: 2rem auto;
            padding: 2rem;

            @include media-breakpoint-down(md) {
                margin: 1rem auto 0;
                padding: 1rem;

                .col-md-2 .form-group {
                    margin-bottom: 0;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .col-md-2 {
                order: 1;
            }

            .col-md-12 {
                order: 0;
            }
        }

        .form-check {
            text-align: center;

            &-label {
                color: white;

                a {
                    color: white;
                }
            }
        }
    }
}
