.form-group {
    margin-bottom: .5rem;

    label {
        font-size: .9rem;
    }

    .form-control {
        &::placeholder {
            font-size: .8rem;
        }
    }

    .form-check {
        &-input, &-label {
            cursor: pointer;

            a {
                color: white;
            }
        }

        &-input {
            margin-top: .75rem;
        }
    }
}
