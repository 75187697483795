////
/// _mixins.scss
/// Aqui devem ser criadas funções para agilizar escrita no SASS.
/// Aprimorar o arquivo novas necessidades forem surgindo.
////

//font
@mixin font($font: false, $size: false, $colour: false, $weight: false,  $lh: false) {
    @if $font { font-family: $font; }
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

/// Mixin para inserção de posicionamento e z-index.
/// Cada variável é opcional e não precisa ser preenchida necessariamente;
/// @param {string} $position [absolute] - Atributo position de CSS
/// @param {number} $top [null] - Valor do posicionamento do topo
/// @param {number} $right [null] - Valor do posicionamento da direita
/// @param {number} $bottom [null] - Valor do posicionamento da base
/// @param {number} $left [null] - Valor do posicionamento da esquerda
/// @param {number} $zindex [null] - Valor de Z-Index do elemento
/// @example @include position($top: 30, $left: 30, $zindex: 9999);
@mixin position($position: absolute, $top: null, $right: null, $bottom: null, $left: null, $zindex: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    z-index: $zindex;
}

/// Mixin para coversão em escala de cinza.
/// Ao incluí-lo, converte o elemento, voltando as suas cores originais no hover.
/// @param {bool} $hover [true] - Habilita a exibição da cor original no hover.
/// @example @include grayscale;
@mixin grayscale($hover: true) {
    $webkit: '-webkit';
    #{$webkit}-filter: grayscale(100%);
    transition: all 0.3s ease 0.1s;
    #{$webkit}-backface-visibility: hidden;
    @if ($hover == true) {
        &:hover {
            filter: none;
            #{$webkit}-filter: grayscale(0%);
        }
    }
}

/// Mixin de edição do texto de Placeholder dos formulários.
/// @param {string} $font Tamanho + Unidade de medida do texto de placeholder.
/// @param {string} $color Cor do texto de placeholder.
/// @example @include placeholder(13px, $black);
@mixin placeholder($font, $color) {
    $prefixes: '&::-webkit-input', '&::-moz', '&:-moz', '&:-ms-input';
    @each $vendor in $prefixes {
        #{$vendor}-placeholder {
            font-size: $font;
            color: $color;
        }
    }
}
