.gotop {
    @include position(fixed, auto, 1.3rem, 1rem, auto, 2);
    background-color: black;
    border: 1px solid white;
    border-radius: 10px;
    position: fixed;
    height: 2.5rem;
    width: 2.5rem;

    @include media-breakpoint-down(md){
        right: unset;
        left: 1rem;
    }

    &:hover, &:focus {
        animation: whatsapp-pulse 1s infinite;
        outline: none;
    }

    &::after {
        content: "";
        transform: translate(-50%, -30%) rotate(45deg);
        border-left: 2px solid #fff;
        border-top: 2px solid #fff;
        position: absolute;
        height: .75rem;
        width: .75rem;
        left: 50%;
        top: 50%;
    }
}
